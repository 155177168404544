
import { SignUp } from '../../Pages/SignUp'
import { Login } from '../../Pages/Login';
import NotFound from '../../Pages/NotFound';
import PersonalInfo from '../Users/PersonalInfo';
import MainTestPage from '../MainTestPage/MainTestPage';
import AdminPage from '../AdminPage/AdminPage';
import WordMemoryTest from '../WordMemory/WordMemoryTest/wordmemorytest';
import TestInstructionsPart2 from '../WordMemory/TestInstructionsPart2/TestInstructionsPart2';
import NumberTest from '../NumberMemory/NumberTest/NumberTest';
import NumberTestReverse from '../NumberMemoryReverse/NumberTestReverse/NumberTestReverse';
import StroopPartATest from '../StroopTest/StroopPartATest/StroopPartATest/StroopPartATest';
import StroopPartBTest from '../StroopTest/StroopPartBTest/StroopPartBTest/StroopPartBTest';
import ResultsPage from '../ResultsPage/ResultsPage';
import PracticeWordMemoryTest from '../WordMemory/PracticeWordMemoryTest/WordMemoryTest/wordmemorytest';
import PracticeNumberTest from '../NumberMemory/PracticeNumberMemory/NumberTest/NumberTest';
import PracticeNumberTestReverse from '../NumberMemoryReverse/PracticeNumberMemoryReverse/NumberTestReverse/NumberTestReverse';
import PracticeStroopPartATest from '../StroopTest/PracticeStroopPartATest/StroopPartATest/StroopPartATest';
import PracticeStroopPartBTest from '../StroopTest/PracticeStroopPartBTest/StroopPartBTest/StroopPartBTest';
import JanKenPonTest from '../JanKenPon/JanKenPonTest/JanKenPonTest'

import UnregisteredPracticeStroopATest from '../StroopTestUnregistered/PracticeStroopPartATest/StroopPartATest/StroopPartATest';
//import UnregisteredPracticeStroopBTest from '../StroopTestUnregistered/PracticeStroopPartBTest/StroopPartBTest/StroopPartBTest';
import UnregisteredStroopATest from '../StroopTestUnregistered/StroopPartATest/StroopPartATest/StroopPartATest';
import UnregisteredStroopBTest from '../StroopTestUnregistered/StroopPartBTest/StroopPartBTest/StroopPartBTest';
import UnregisteredPracticeStroopBTest from '../StroopTestUnregistered/PracticeStroopPartBTest/StroopPartBTest/StroopPartBTest';
import UnregisteredPracticeNumberMemory from '../NumberMemoryUnregistered/PracticeNumberMemory/NumberTest/NumberTest';
import UnregisteredNumberMemory from '../NumberMemoryUnregistered/NumberTest/NumberTest';
import UnregisteredResults from '../ResultsPageUnregistered/ResultsPage'
//import UnregisteredJanKenPon from '../JanKenPonUnregistered/'
import WelcomePage from '../WelcomePage/WelcomePage';


const routes = [
  {
		path: '/welcome',
		component: WelcomePage,
		isPrivate: false,
	},
  {
		path: '/signup',
		component: SignUp,
		isPrivate: false,
	},
	{
		path: '/login',
		component: Login,
		isPrivate: false,
	},
  {
		path: '/practice/unregistered-stroop-test-a',
		component: UnregisteredPracticeStroopATest,
		isPrivate: false,
	},
  {
		path: '/practice/unregistered-stroop-test-b',
		component: UnregisteredPracticeStroopBTest,
		isPrivate: false,
	},
  {
		path: '/test/unregistered-stroop-test-a',
		component: UnregisteredStroopATest,
		isPrivate: false,
	},
  {
		path: '/test/unregistered-stroop-test-b',
		component: UnregisteredStroopBTest,
		isPrivate: false,
	},
  {
		path: '/practice/unregistered-number-memory',
		component: UnregisteredPracticeNumberMemory,
		isPrivate: false,
	},
  {
		path: '/test/unregistered-number-memory',
		component: UnregisteredNumberMemory,
		isPrivate: false,
	},
  {
		path: '/unregistered-results',
		component: UnregisteredResults,
		isPrivate: false,
	},
  {
		path: '/personal-info',
		component: PersonalInfo,
		isPrivate: true,
	},
	{
		path: '/tests',
		component: MainTestPage,
		isPrivate: true,
	},
  {
		path: '/admin',
		component: AdminPage,
		isPrivate: true,
	},
  {
		path: '/practice/word-memory',
		component: PracticeWordMemoryTest,
		isPrivate: true,
	},
  {
		path: '/practice/stroop-test-a',
		component: PracticeStroopPartATest,
		isPrivate: true,
	},
  {
		path: '/practice/stroop-test-b',
		component: PracticeStroopPartBTest,
		isPrivate: true,
	},
  {
		path: '/practice/number-memory-reverse',
		component: PracticeNumberTestReverse,
		isPrivate: true,
	},
  {
		path: '/practice/number-memory',
		component: PracticeNumberTest,
		isPrivate: true,
	},
  // {
	// 	path: '/practice/jankenpon',
	// 	component: StroopPartBTest,
	// 	isPrivate: true,
	// },
  {
		path: '/test/word-memory',
		component: WordMemoryTest,
		isPrivate: true,
	},
  {
		path: '/test/word-memory-2',
		component: TestInstructionsPart2,
		isPrivate: true,
	},
  {
		path: '/test/number-memory',
		component: NumberTest,
		isPrivate: true,
	},
  {
		path: '/test/number-memory-reverse',
		component: NumberTestReverse,
		isPrivate: true,
	},
  {
		path: '/test/stroop-test-a',
		component: StroopPartATest,
		isPrivate: true,
	},
  {
		path: '/test/stroop-test-b',
		component: StroopPartBTest,
		isPrivate: true,
	},
  {
		path: '/test/jankenpon',
		component: JanKenPonTest,
		isPrivate: true,
	},
  {
		path: '/results',
		component: ResultsPage,
		isPrivate: true,
	},
  {
		path: '/404',
		component: NotFound,
		isPrivate: true,
	},
  {
    path: '/',
    exact: true,
    component: MainTestPage,
    isPrivate: true,
  },
  {
		path: '/*',
		component: NotFound,
		isPrivate: true,
	},
];

export default routes;
