import React, { useState, useRef, useEffect } from "react";
import "./jankenpon-test.css";
import TestInstructions from "../TestInstructions/TestInstructions";
import TestCompleted from "../TestCompleted/TestCompleted";
import { useAuthState } from "../../../Context";
import HeaderLogo from '../../HeaderLogo/HeaderLogo'
import RockImage from '../../../Images/JanKenPon/Rock.png';
import PaperImage from '../../../Images/JanKenPon/Paper.png';
import ScissorsImage from '../../../Images/JanKenPon/Scissors.png';
import PaperHand from '../../../Images/JanKenPon/PaperHand.png';
import RockHand from '../../../Images/JanKenPon/RockHand.png';
import ScissorsHand from '../../../Images/JanKenPon/ScissorsHand.png';
import GreenCheck from '../../../Images/JanKenPon/GreenCheck.png';
import RedCross from '../../../Images/JanKenPon/RedCross.png';


//import ProgressBar from "../../../ProgressBar/ProgressBar";
//let testProgressBar = 1;

export default function JanKenPonTest() {
  const userDetails = useAuthState();
  const user = userDetails.user;

  const handleButtonClick = (buttonName) => {
    console.log(`${buttonName} clicked`);
  };

  return (
    <>
      <HeaderLogo />
      <div className="game-container">
        <div className="hand-sign">
          <img src={PaperHand} alt="Hand Sign" />
        </div>
        <div className="button-container">
          <button onClick={() => handleButtonClick('rock')}>
            <img src={RockImage} alt="Rock" />
          </button>
          <button onClick={() => handleButtonClick('paper')}>
            <img src={PaperImage} alt="Paper" />
          </button>
          <button onClick={() => handleButtonClick('scissors')}>
            <img src={ScissorsImage} alt="Scissors" />
          </button>
        </div>
      </div>

      {/* <p>Rock Paper Scissors</p>
      <p>ORDER!</p>
      <div className = "score-info ">
        <span>
          {user.is_admin && <div className = "total-score ">Score: <span style={{ color: 'blue' }}>{ "correctScore" } </span>| <span style={{ color: 'red' }}>{"incorrectScore" } </span></div>}
          {user.is_admin && <div className = "total-games">ラウンド: { 1 } / 100</div>}
        <div className = "total-games">残り時間: { "timer" }</div>
        </span>
      </div> */}

      {/* Image Container */}

      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src={RockImage} alt="Centered Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
      </div> */}


    </>
  );
}
