//No visits => Display only Brain Icon and Start button
//1 Visit => Total Score out of 300!!
//Ages: 30-39
//      40-49
//      50-59
//      60-69
//SCORING:
//Stroop B  100 is 50            Each answer = 0.5point
//Number Memory  7 is 50         Each answer = 7.14points

//業務処理機能
//記憶・処理機能
//判断・処理機能

//  この分類です。
// 【業務処理能力】　　Number Memory Total
//  数唱テスト 順唱
// 【判断・処理能力】　　Stroop Total
//  ストループテスト2
//業務処理能力  Number Memory Total
//判断・処理能力  Stroop Total

import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { logout, useAuthDispatch } from "../../Context";
import { Link } from "react-router-dom";
import "./results-page.css";
import logo from "../../Images/cog-smart-logo.png"
// import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import HeaderLogo from '../HeaderLogo/HeaderLogo'

export default function ResultsPage() {
  const [isBusy, setBusy] = useState(true);
  const [currentHospital, setCurrentHospital] = useState('');
  const dispatch = useAuthDispatch();
  let resultsRef = useRef();

  let totalScore = useRef([]);
  let taskDeviationValue = useRef([]);
  let judgementDeviationValue = useRef([]);
  let testPlottingResultsArray = useRef([]);
  let totalDeviationValue = useRef([]);
  let testsDeviationArray = useRef([])
  let totalScoreDeviationPercentage = useRef([])
  let testScoresDeviationPercentages = useRef([])
  let textStyleThreshold = 20
  let gender = useRef([])
  let fromAge = useRef([])
  let toAge = useRef([])

  const history = useHistory();

  const HospitalObject = {
    "localhost": {　//WHEN THERE IS NO SUBDOMAIN DEFAULT TO COGTEST SUBDOMAIN CONTENTS (FOR TESTING TOO)
    customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では、こういったテストでは測ることの出来ない実際の脳の状態を測定可能！人工知能（AI）を用いて海馬体積や微細な萎縮程度を精密に解析し、将来の認知機能低下の兆候を計測します。",
    customURL: "https://www.brainsuite.jp/",
    customButtonText: "詳細はこちら"
    },
    "fake1": { //THIS IS Test HOSPITAL
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>FAKE1病院では人間ドックのオプションとして、BrainSuiteを提供しています。",
      customURL: "https://www.fake.com",
      customButtonText: "Fakeお申込はこちら"
    },
    "asakusa": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>浅草病院では、BrainSuiteを提供しています。",
      customURL: "https://www.asakusa-hp.jp/checkup/dock.php",
      customButtonText: "お申込はこちら"
    },
    "braintest": { //THIS IS MITA HOSPITAL
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>三田病院では人間ドックのオプションとして、BrainSuiteを提供しています。",
      customURL: "https://form.run/@mita-brainsuite",
      customButtonText: "お申込はこちら"
    },
    "check": {　//GENERAL USE MARKETING 3分チェック
      customText: "あなたの脳の健康状態はいかがでしたか？<br>記憶をつかさどる脳の「海馬」は30代より萎縮が始まると言われていますが、有酸素運動等の生活習慣により大きくすることもでき、認知機能の維持や認知症の予防に繋げることもできます。<br>「BrainSuite」は海馬の大きさを人工知能（AI）を用いて精密に測定できる脳検査で、あなたの年齢で維持するべき海馬の大きさがわかります。東北大学加齢医学研究所の研究成果に基づいて、あなたの脳健康をサポートします。",
      customURL: "https://www.brainsuite.jp/",
      customButtonText: "詳細はこちら"
    },
    "ichibancho": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>一番町健診クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.sendaikenshin.jp/dock-course/option01.php#brainsuite",
      customButtonText: "詳しくはこちら"
    },
    "kamiya": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>神谷医院では、BrainSuiteを提供しています。",
      customURL: "https://www.mrso.jp/saitama/soka/kamiya-clinic/plan/47381/",
      customButtonText: "詳しくはこちら"
    },
    "kiyose": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>武蔵野総合クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://6340cl.wixsite.com/cliniccheck",
      customButtonText: "詳しくはこちら"
    },
    "komazawa": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>駒沢健康管理センターでは、BrainSuiteを提供しています。",
      customURL: "https://komazawa-cl.jp/center/doc/",
      customButtonText: "詳しくはこちら"
    },
    "kuzuha": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>くずは画像診断クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://kuzuha-rad.jp/",
      customButtonText: "詳しくはこちら"
    },
    "maki": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>牧病院では、BrainSuiteを提供しています。",
      customURL: "https://www.maki-group.jp/maki/doc/",
      customButtonText: "詳しくはこちら"
    },
    "medoc": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>メドック健康クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://medoc.jp/dock/#dock12",
      customButtonText: "詳しくはこちら"
    },
    "megalos": {
      customText: "あなたの脳の状態はいかがでしたか？有酸素運動をすると脳血流が上昇し、血液を通じて脳へ酸素や栄養素が行き渡るようになるため、脳の働きが活性化されます。<br><br>メガロスでは頭部MRI画像より脳の海馬体積をAIで計測できる「BrainSuite」を提供する株式会社CogSmartと連携。数多くのトレーニングプログラムを用意しており、見学予約・体験予約もできます。",
      customURL: "https://www.megalos.co.jp/contents/visitor/",
      customButtonText: "詳しくはこちら"
    },
    "nagoya": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>名古屋掖済会病院では、BrainSuiteを提供しています。",
      customURL: "https://www.nagoya-ekisaikaihosp.jp/checkup-2",
      customButtonText: "詳しくはこちら"
    },
    "nozomi": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>芦屋クリニックのぞみでは、BrainSuiteを提供しています。",
      customURL: "https://coubic.com/ashiya-clinicnozomi/booking_pages#pageContent",
      customButtonText: "詳しくはこちら"
    },
    "ookuma": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>大隈病院では、BrainSuiteを提供しています。",
      customURL: "https://www.ookuma-hospital.or.jp/introduction/introduction_7345/brain_dock/",
      customButtonText: "詳しくはこちら"
    },
    "san": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>賛育会病院 健康管理クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.san-ikukai.or.jp/sumida/hospital/doc/option.html",
      customButtonText: "詳しくはこちら"
    },
    "seirei": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>聖隷沼津健康診断センターでは、BrainSuiteを提供しています。",
      customURL: "https://www.seirei.or.jp/numazu-kenshin/dock/156.html",
      customButtonText: "詳しくはこちら"
    },
    "sobajima": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>そばじまクリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.mrso.jp/mrs/sbjm/Plans/selectPlan",
      customButtonText: "詳しくはこちら"
    },
    "sugawara": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>菅原クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://tokyo-noudock.jp/dock/",
      customButtonText: "詳しくはこちら"
    },
    "tajimi": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>多治見市民病院では、BrainSuiteを提供しています。",
      customURL: "http://kouseikai-tajimi-shimin.jp/brainsuite/",
      customButtonText: "詳しくはこちら"
    },
    "tanaka": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>田中病院では、BrainSuiteを提供しています。",
      customURL: "https://www.mrso.jp/mrs/tanakahp/Plans/selectPlan/",
      customButtonText: "詳しくはこちら"
    },
    "totsuka": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>戸塚共立メディカルサテライト 健診センターでは、BrainSuiteを提供しています。",
      customURL: "https://yoyaku.medical-satellite.com/?all",
      customButtonText: "詳しくはこちら"
    },
    "ueno": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>うえのとしあき脳神経外科クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.ueno-toshiaki.com/",
      customButtonText: "詳しくはこちら"
    },
    "uozumi": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>魚住クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://uozumi.or.jp/course/",
      customButtonText: "詳しくはこちら"
    },
    "umeda": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>梅田東画像診断クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://umedahigashi-rad.jp/",
      customButtonText: "詳しくはこちら"
    },
  }


  useEffect(() => {
    let csrftoken = Cookies.get("csrf_refresh_token");
    fetch("/api/results", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRFToken": csrftoken,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log("Results in React", response);
        updateResults(response);
        setGender(resultsRef.current.gender)
        setAgeRange(resultsRef.current.from_age, resultsRef.current.to_age)
        setBusy(false);
      })
      .catch((err) => {
        console.log("err", err);
        logout(dispatch);
      });
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const hostname = window.location.hostname;

    if (hostname === "localhost") {
        setCurrentHospital("localhost");
        console.log("Subdomain: localhost");
        return;
    }

    const parts = hostname.split('.');// parts[0] should be the subdomain if there is any
    if (parts.length > 2) {
        const subdomain = parts[0];
        setCurrentHospital(subdomain);
        //console.log("Subdomain: ", subdomain);
    } else {
        console.log("No subdomain in use");
    }
}, []);

  const updateResults = (response) => {
    resultsRef.current = response;
    //console.log("resultsRef in updateResults", resultsRef.current);
    //console.log("resultsRef.current", resultsRef.current.gender)

    // resultsRef.current = {email: 'carlosiusalazar@gmail.com', is_testing_allowed: true, results: [{current_period: '1ヶ月', diff_total_score: 27, judgement_deviation_value: 24, judgement_score: 22, next_period : "3ヶ月", task_deviation_value : 27, task_score: 29, total_deviation_value: 9,total_score: 65}], user_name: 'Carlos'}


    parseVisits(resultsRef.current);
  };

  const setGender = (responseGender) => {
    if (responseGender === 'male') {
      gender.current = '男性'
    } else if (gender === 'female') {
      gender.current = '女性'
    } else {
      gender.current = 'その他'
    }
  }

  const setAgeRange = (to, from) => {
    fromAge.current  = to
    toAge.current = from
  }


  const parseVisits = (results) => {
    //console.log("Results only", results.results);

    if (results.results.length === 0) {
      history.push('/tests')
      return <Redirect to='/tests' />
    }

    let testPlottingResults = {
      judgementCapacity: 0,
      taskProcessingCapacity: 0,
    };

    //totalScore.current = results.results.at(-1)["total_score"]; // .at(-1) doesn't work well in all browsers
    totalScore.current = results.results[results.results.length - 1]["total_score"];
    totalDeviationValue.current = [results.results[results.results.length - 1]['total_deviation_value']]
    // totalDeviationValue.current = [60]  // Hack here the total score deviation

    taskDeviationValue.current = results.results[results.results.length - 1]["task_deviation_value"];
    judgementDeviationValue.current = results.results[results.results.length - 1]["judgement_deviation_value"];

    testPlottingResults["judgementCapacity"] = results.results[results.results.length - 1]["judgement_score"];
    testPlottingResults["taskProcessingCapacity"] = results.results[results.results.length - 1]["task_score"];

    testPlottingResultsArray.current = [...testPlottingResultsArray.current, testPlottingResults];

    testsDeviationArray.current = [judgementDeviationValue.current, taskDeviationValue.current]
    // testsDeviationArray.current = [39,19,60]   // Hack here the 3 tests deviation value for testing.

    testPlottingResults = {
      judgementCapacity: 0,
      taskProcessingCapacity: 0,
    };

    // console.log("totalDeviationValue.current", totalDeviationValue.current)
    // console.log("totalDeviationValue", totalDeviationValue)
    // console.log("testsDeviationArray.current", testsDeviationArray.current)

    totalScoreDeviationPercentage.current = calculateDeviationBarPercentages(totalDeviationValue.current)
    testScoresDeviationPercentages.current = calculateDeviationBarPercentages(testsDeviationArray.current)

  }

  const calculateDeviationBarPercentages = (deviations) => {
    // This function converts the values of the total Deviation arrays into an equivalent percentage for its blue bar graph to properly show.
    // if greater than 80 then it'll use 100% of the width. If less than 20 then it'll use 5% (not 0% since values less than 5% is hard to see on screen and pure white doesn't look good)
    // if a deviation array comes as [12, 50, 90] it's equivalent percentage array will be [5, 50, 100]
    let deviationPercentagesArray = []
    deviations.forEach(element => {
      element <= 20 ? deviationPercentagesArray.push(5) : element >= 80 ? deviationPercentagesArray.push(100) : deviationPercentagesArray.push(Math.round(element))
    })

    //console.log("deviationPercentagesArray",deviationPercentagesArray)
    return deviationPercentagesArray
  }


  // const getChartDataBar = (testResults) => {
  //   return {
  //     labels: [`記憶力`, `注意力`, `処理機能`],
  //     datasets: [
  //       {
  //         label: "テスト結果",
  //         borderWidth: 0.5,
  //         backgroundColor: "rgba(6, 179, 232, 0.80)",
  //         borderColor: "#fff",
  //         lineTension: 0,
  //         pointRadius: 5,
  //         pointBorderColor: "#000",
  //         pointHoverBackgroundColor: "#fff",
  //         pointHoverBorderColor: "rgb(54, 162, 235)",
  //         data: [
  //           testResults["judgementCapacity"],
  //           testResults["taskProcessingCapacity"],
  //         ],
  //       },
  //     ],
  //   };
  // };

  // const optionsBar = {
  //   indexAxis: "y",
  //   // Elements options apply to all of the options unless overridden in a dataset
  //   // In this case, we are setting the border of each horizontal bar to be 2px wide
  //   elements: {
  //     bar: {
  //       borderWidth: 2,
  //     },
  //   },

  //   responsive: true,
  //   maintainAspectRatio: true,
  //   layout: {
  //     beginAtZero: true,
  //   },
  //   scale: {
  //     gridLines: {
  //       circular: true,
  //     },

  //   },
  //   scales : {
  //     x : {
  //         min: 0,
  //         max: 100,
  //     }
  // },
  // };


  return (
    <>
      <HeaderLogo />
      <div className="head welcome-text-button-results-container">
        <div className="welcome-text-container">
          <h1>テスト結果</h1>
        </div>
      </div>
      {!isBusy && testPlottingResultsArray.current[0] &&
        <>
                        <div className="result-inner">

          <div className="historic-results-box">
            <div className="historic-results-header">
              <p>全国の<span style={ {color : "red"}}>{fromAge.current}~{toAge.current}歳の{gender.current}</span>と比較した<br></br>あなたの偏差値は・・・？</p>
            </div>

            {/* 総合点 */}
            <div className="score__grid">
              <span className="item1">総合点</span>
              <span className="score"><b>{totalScore.current}</b><small>/100</small></span><span className="item3">点</span>
            </div>
            {/* eslint-disable-next-line */}
            <div className="deviation-container" style={{width:`${totalScoreDeviationPercentage.current[0]}`+'%'}}>
              {totalScoreDeviationPercentage.current[0] <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 {totalDeviationValue.current[0]}</p>
                :
                <p className="deviation-text">偏差値 {totalDeviationValue.current[0]}</p>
              }
            </div>

            {/* 注意力 */}
            <div className="score__grid">
              <span className="item1">注意力テスト</span>  {/*Stroop test*/}
              <span className="score"><b>{testPlottingResultsArray.current[0]["judgementCapacity"]}</b><small>/50</small></span>
              <span className="item3">点</span>
            </div>
            {/* eslint-disable-next-line */}
            <div className="deviation-container" style={{width:`${testScoresDeviationPercentages.current[0]}`+'%'}}>
              {testScoresDeviationPercentages.current[0] <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 {testsDeviationArray.current[0]}</p>
                :
                <p className="deviation-text">偏差値 {testsDeviationArray.current[0]}</p>
              }
            </div>

            {/* 処理機能 */}
            <div className="score__grid">
              <span className="item1">処理機能テスト</span>  {/*Number*/}
              <span className="score"><b>{testPlottingResultsArray.current[0]["taskProcessingCapacity"]}</b><small>/50</small></span>
              <span className="item3">点</span>
            </div>
            {/* eslint-disable-next-line */}
            <div className="deviation-container" style={{width:`${testScoresDeviationPercentages.current[1]}`+'%'}}>
            {testScoresDeviationPercentages.current[1] <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 {testsDeviationArray.current[1]}</p>
                :
                <p className="deviation-text">偏差値 {testsDeviationArray.current[1]}</p>
              }
            </div>

            {/* 記憶力 ONLY AVAILABLE IN FULL VERSION*/}

            <p>※ご入力いただいたご年齢の±５歳の方と比較しています。</p>

          </div>
          </div>
        </>
      }
      {!isBusy && testPlottingResultsArray.current[0] &&
        <>
          {/* <Link to="/tests" className="continue-button close">
            <h2>閉じる</h2>
          </Link> */}
          <div className="result-inner">
            <div className="historic-results-box">
            {currentHospital && <p className="points-text" dangerouslySetInnerHTML={{ __html: HospitalObject[currentHospital].customText }} />}
            {/* <p className="points-text">あなたの脳の状態はいかがでしたか？「BrainSuite」では、こういったテストでは測ることの出来ない実際の脳の状態を測定可能！人工知能（AI）を用いて海馬体積や微細な萎縮程度を精密に解析し、将来の認知機能低下の兆候を計測します。</p> */}
            <br></br>
            {/* <a href="https://www.brainsuite.jp" target="_blank" rel="noopener noreferrer"><button className="show-words-btn">詳細はこちら</button></a> */}
            {currentHospital && <a href={HospitalObject[currentHospital].customURL} target="_blank" rel="noopener noreferrer"><button className="show-words-btn">{HospitalObject[currentHospital].customButtonText}</button></a>}
            </div>

            <div>
              <p className="follow-us-text">FOLLOW US</p>
              <p className="horizontal-line">_________</p>
              <p className="line-text">
                LINE公式アカウントでは脳の健康に関する
                <br />
                お役立ち情報を配信中！
              </p>
              <div className="line-button-container">
                <a href="https://lin.ee/GGS5qhK"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="64" width="auto" border="0" /></a>
              </div>
            </div>

            <br></br>

            <div>
              <img src={logo} alt="logo-image" width="20%" height="20%"/>
            </div>
          </div>
        </>
      }
    </>
  )
};
