//No visits => Display only Brain Icon and Start button
//1 Visit => Total Score out of 300!!
//Ages: 30-39
//      40-49
//      50-59
//      60-69
//SCORING:
//Stroop B  100 is 50            Each answer = 0.5point
//Number Memory  7 is 50         Each answer = 7.14points

//業務処理機能
//記憶・処理機能
//判断・処理機能

//  この分類です。
// 【業務処理能力】　　Number Memory Total
//  数唱テスト 順唱
// 【判断・処理能力】　　Stroop Total
//  ストループテスト2
//業務処理能力  Number Memory Total
//判断・処理能力  Stroop Total

import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { logout, useAuthDispatch } from "../../Context";
import { Link } from "react-router-dom";
import "./results-page.css";
import logo from "../../Images/cog-smart-logo.png"
// import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import HeaderLogo from '../HeaderLogo/HeaderLogo'

export default function ResultsPage() {
  const [isBusy, setBusy] = useState(true);
  const [currentHospital, setCurrentHospital] = useState('');
  const dispatch = useAuthDispatch();
  let resultsRef = useRef();
  let totalScore = useRef([]);
  let taskDeviationValue = useRef([]);
  let judgementDeviationValue = useRef([]);
  let testPlottingResultsArray = useRef([]);
  let totalDeviationValue = useRef([]);
  let testsDeviationArray = useRef([])
  let totalScoreDeviationPercentage = useRef([])
  let testScoresDeviationPercentages = useRef([])
  let textStyleThreshold = 20;
  let unregisteredStroopScore = 0;
  let unregisteredNumberScore = 0;
  let unregisteredStroopTotalScore = useRef();
  let unregisteredNumberTotalScore = useRef();
   // New State
   const [showModal, setShowModal] = useState(false);
   const [tempGender, setTempGender] = useState();  //The tempAge and tempGender exists so that the Age and Gender is not confirmed until the user hits the confirm modal button. If he cancels no age is saved in localstorage.
   const [tempAge, setTempAge] = useState(30);
   const [isCustomAge, setIsCustomAge] = useState(false);
   const [gender, setGender] = useState();
   const [age, setAge] = useState();
   const [judgementDeviation, setJudgementDeviation] = useState();
   const [taskDeviation, setTaskDeviation] = useState();
   const [totalDeviation, setTotalDeviation] = useState();
   const [showingDeviationBox, setShowingDeviationBox] = useState(true);

  const history = useHistory();


  const HospitalObject = {
    "localhost": {　//WHEN THERE IS NO SUBDOMAIN DEFAULT TO COGTEST SUBDOMAIN CONTENTS (FOR TESTING TOO)
    customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では、こういったテストでは測ることの出来ない実際の脳の状態を測定可能！人工知能（AI）を用いて海馬体積や微細な萎縮程度を精密に解析し、将来の認知機能低下の兆候を計測します。",
    customURL: "https://www.brainsuite.jp/",
    customButtonText: "詳細はこちら"
    },
    "fake1": { //THIS IS Test HOSPITAL
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>FAKE1病院では人間ドックのオプションとして、BrainSuiteを提供しています。",
      customURL: "https://www.fake.com",
      customButtonText: "Fakeお申込はこちら"
    },
    "asakusa": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>浅草病院では、BrainSuiteを提供しています。",
      customURL: "https://www.asakusa-hp.jp/checkup/dock.php",
      customButtonText: "お申込はこちら"
    },
    "braintest": { //THIS IS MITA HOSPITAL
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>三田病院では人間ドックのオプションとして、BrainSuiteを提供しています。",
      customURL: "https://form.run/@mita-brainsuite",
      customButtonText: "お申込はこちら"
    },
    "check": {　//GENERAL USE MARKETING 3分チェック
      customText: "あなたの脳の健康状態はいかがでしたか？<br>記憶をつかさどる脳の「海馬」は30代より萎縮が始まると言われていますが、有酸素運動等の生活習慣により大きくすることもでき、認知機能の維持や認知症の予防に繋げることもできます。<br>「BrainSuite」は海馬の大きさを人工知能（AI）を用いて精密に測定できる脳検査で、あなたの年齢で維持するべき海馬の大きさがわかります。東北大学加齢医学研究所の研究成果に基づいて、あなたの脳健康をサポートします。",
      customURL: "https://www.brainsuite.jp/",
      customButtonText: "詳細はこちら"
    },
    "ichibancho": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>一番町健診クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.sendaikenshin.jp/dock-course/option01.php#brainsuite",
      customButtonText: "詳しくはこちら"
    },
    "kamiya": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>神谷医院では、BrainSuiteを提供しています。",
      customURL: "https://www.mrso.jp/saitama/soka/kamiya-clinic/plan/47381/",
      customButtonText: "詳しくはこちら"
    },
    "kiyose": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>武蔵野総合クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://6340cl.wixsite.com/cliniccheck",
      customButtonText: "詳しくはこちら"
    },
    "komazawa": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>駒沢健康管理センターでは、BrainSuiteを提供しています。",
      customURL: "https://komazawa-cl.jp/center/doc/",
      customButtonText: "詳しくはこちら"
    },
    "kuzuha": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>くずは画像診断クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://kuzuha-rad.jp/",
      customButtonText: "詳しくはこちら"
    },
    "maki": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>牧病院では、BrainSuiteを提供しています。",
      customURL: "https://www.maki-group.jp/maki/doc/",
      customButtonText: "詳しくはこちら"
    },
    "medoc": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>メドック健康クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://medoc.jp/dock/#dock12",
      customButtonText: "詳しくはこちら"
    },
    "megalos": {
      customText: "あなたの脳の状態はいかがでしたか？有酸素運動をすると脳血流が上昇し、血液を通じて脳へ酸素や栄養素が行き渡るようになるため、脳の働きが活性化されます。<br><br>メガロスでは頭部MRI画像より脳の海馬体積をAIで計測できる「BrainSuite」を提供する株式会社CogSmartと連携。数多くのトレーニングプログラムを用意しており、見学予約・体験予約もできます。",
      customURL: "https://www.megalos.co.jp/contents/visitor/",
      customButtonText: "詳しくはこちら"
    },
    "nagoya": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>名古屋掖済会病院では、BrainSuiteを提供しています。",
      customURL: "https://www.nagoya-ekisaikaihosp.jp/checkup-2",
      customButtonText: "詳しくはこちら"
    },
    "nozomi": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>芦屋クリニックのぞみでは、BrainSuiteを提供しています。",
      customURL: "https://coubic.com/ashiya-clinicnozomi/booking_pages#pageContent",
      customButtonText: "詳しくはこちら"
    },
    "ookuma": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>大隈病院では、BrainSuiteを提供しています。",
      customURL: "https://www.ookuma-hospital.or.jp/introduction/introduction_7345/brain_dock/",
      customButtonText: "詳しくはこちら"
    },
    "san": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>賛育会病院 健康管理クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.san-ikukai.or.jp/sumida/hospital/doc/option.html",
      customButtonText: "詳しくはこちら"
    },
    "seirei": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>聖隷沼津健康診断センターでは、BrainSuiteを提供しています。",
      customURL: "https://www.seirei.or.jp/numazu-kenshin/dock/156.html",
      customButtonText: "詳しくはこちら"
    },
    "sobajima": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>そばじまクリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.mrso.jp/mrs/sbjm/Plans/selectPlan",
      customButtonText: "詳しくはこちら"
    },
    "sugawara": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>菅原クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://tokyo-noudock.jp/dock/",
      customButtonText: "詳しくはこちら"
    },
    "tajimi": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>多治見市民病院では、BrainSuiteを提供しています。",
      customURL: "http://kouseikai-tajimi-shimin.jp/brainsuite/",
      customButtonText: "詳しくはこちら"
    },
    "tanaka": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>田中病院では、BrainSuiteを提供しています。",
      customURL: "https://www.mrso.jp/mrs/tanakahp/Plans/selectPlan/",
      customButtonText: "詳しくはこちら"
    },
    "totsuka": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>戸塚共立メディカルサテライト 健診センターでは、BrainSuiteを提供しています。",
      customURL: "https://yoyaku.medical-satellite.com/?all",
      customButtonText: "詳しくはこちら"
    },
    "ueno": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>うえのとしあき脳神経外科クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://www.ueno-toshiaki.com/",
      customButtonText: "詳しくはこちら"
    },
    "uozumi": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>魚住クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://uozumi.or.jp/course/",
      customButtonText: "詳しくはこちら"
    },
    "umeda": {
      customText: "あなたの脳の状態はいかがでしたか？「BrainSuite」では実際の脳(海馬)の健康状態をAIを用いてさらに詳しく、具体的な数値で知ることが出来ます。ぜひ一度ご自身の「脳の健康診断」を行ってみませんか。<br>※医療機関で短時間のMRI検査が必要となります。<br><br>梅田東画像診断クリニックでは、BrainSuiteを提供しています。",
      customURL: "https://umedahigashi-rad.jp/",
      customButtonText: "詳しくはこちら"
    },
  }

  useEffect(() => {
    updateResults();
    unregisteredStroopTotalScore.current = localStorage.getItem("StroopScore");
    unregisteredNumberTotalScore.current = localStorage.getItem("NumberScore");

    // calculateUnregisteredResults()
    setBusy(false);
    //console.log("Unregistered Stroop Score",unregisteredStroopTotalScore.current)
    //console.log("Unregistered Stroop Score",unregisteredNumberTotalScore.current)
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const hostname = window.location.hostname;

    if (hostname === "localhost") {
        setCurrentHospital("localhost");
        console.log("Subdomain: localhost");
        return;
    }

    const parts = hostname.split('.');// parts[0] should be the subdomain if there is any
    if (parts.length > 2) {
        const subdomain = parts[0];
        setCurrentHospital(subdomain);
        //console.log("Subdomain: ", subdomain);
    } else {
        console.log("No subdomain in use");
    }
}, []);

  const updateResults = () => {
    resultsRef.current = {'user_name': 'MarketingTest',
                          'email': 'marketingtest1@gmail.com',
                          'results': [{'task_score': 43,
                                      'judgement_score': 23,
                                      'total_score': 66,
                                      'total_deviation_value': 52,
                                      'task_deviation_value': 53,
                                      'judgement_deviation_value': 50,
                                      'diff_total_score': 56}],
                          'is_testing_allowed': 'False'}

    parseVisits(resultsRef.current);
  };

  // Show Modal
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleAgeChange = (e) => {
    const value = e.target.value;

    if (value === "custom") {
        setIsCustomAge(true);
        setTempAge("");  // Set age to empty to allow for user input
    } else {
        setIsCustomAge(false);
        setTempAge(value);
    }
  };

  // Handle modal form submission
  const handleConfirm = () => {
    //The main age and gender values (age and gender) will only be updated when the user confirms the modal.
    //The values will be stored in localStorage only upon confirmation.
    //If the user cancels the modal, the temporary modal input state is discarded, and the main state remains unaffected.
    localStorage.setItem("userGender", tempGender);
    localStorage.setItem("userAge", tempAge);
    setGender(tempGender);
    setAge(tempAge);
    setShowingDeviationBox(false);

    let scoresForDeviation = {
      "total_score" : (parseInt(localStorage.getItem("StroopScore"))/2) + (parseInt(localStorage.getItem("NumberScore"))*7.15),
      "task_score" : parseInt(localStorage.getItem("NumberScore"))*7.15,
      "judgement_score" : parseInt(localStorage.getItem("StroopScore"))/2
    }

    async function getDeviation(age, gender, scores) {
      const apiUrl = "api/deviation_results";
      //console.log("scores for deviationnn", scoresForDeviation)
      const requestData = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              gender: localStorage.getItem("userGender"),
              age: localStorage.getItem("userAge"),
              scores : scoresForDeviation
          })
      };

      try {
          const response = await fetch(apiUrl, requestData);

          if (response.ok) {
              const data = await response.json();
              //console.log("dataaaa", data);

          // Extract the values from the 'results' array
          const result = data.results[0];

          // Update the state values
          setJudgementDeviation(result.judgement_deviation_value);
          setTaskDeviation(result.task_deviation_value);
          setTotalDeviation(result.total_deviation_value);

          } else {
              console.error('Failed to fetch:', response.status, response.statusText);
          }
      } catch (error) {
          console.error('Fetch error:', error.message);
      }
  }

    getDeviation(age, gender, scoresForDeviation)

    setShowModal(false);

    // window.location.reload(); // reload the page
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const parseVisits = (results) => {
    //console.log("Results only", results.results);

    if (results.results.length === 0) {
      history.push('/tests')
      return <Redirect to='/tests' />
    }

    let testPlottingResults = {
      judgementCapacity: 0,
      taskProcessingCapacity: 0,
    };

    //totalScore.current = results.results.at(-1)["total_score"]; // .at(-1) doesn't work well in all browsers
    totalScore.current = results.results[results.results.length - 1]["total_score"];
    totalDeviationValue.current = [results.results[results.results.length - 1]['total_deviation_value']]
    // totalDeviationValue.current = [60]  // Hack here the total score deviation

    taskDeviationValue.current = results.results[results.results.length - 1]["task_deviation_value"];
    judgementDeviationValue.current = results.results[results.results.length - 1]["judgement_deviation_value"];

    testPlottingResults["judgementCapacity"] = results.results[results.results.length - 1]["judgement_score"];
    testPlottingResults["taskProcessingCapacity"] = results.results[results.results.length - 1]["task_score"];

    testPlottingResultsArray.current = [...testPlottingResultsArray.current, testPlottingResults];

    testsDeviationArray.current = [judgementDeviationValue.current, taskDeviationValue.current]
    // testsDeviationArray.current = [39,19,60]   // Hack here the 3 tests deviation value for testing.

    testPlottingResults = {
      judgementCapacity: 0,
      taskProcessingCapacity: 0,
    };


    totalScoreDeviationPercentage.current = calculateDeviationBarPercentages(totalDeviationValue.current)
    testScoresDeviationPercentages.current = calculateDeviationBarPercentages(testsDeviationArray.current)

  }

  const calculateDeviationBarPercentages = (deviations) => {
    // This function converts the values of the total Deviation arrays into an equivalent percentage for its blue bar graph to properly show.
    // if greater than 80 then it'll use 100% of the width. If less than 20 then it'll use 5% (not 0% since values less than 5% is hard to see on screen and pure white doesn't look good)
    // if a deviation array comes as [12, 50, 90] it's equivalent percentage array will be [5, 50, 100]
    let deviationPercentagesArray = []
    deviations.forEach(element => {
      element <= 20 ? deviationPercentagesArray.push(5) : element >= 80 ? deviationPercentagesArray.push(100) : deviationPercentagesArray.push(Math.round(element))
    })

    //console.log("deviationPercentagesArray",deviationPercentagesArray)
    return deviationPercentagesArray
  }


  return (
    <>
      <HeaderLogo />
      <div className="head welcome-text-button-results-container">
        <div className="welcome-text-container">
          <h1>テスト結果</h1>
        </div>
      </div>
      {!isBusy && testPlottingResultsArray.current[0] &&
        <>
          <div className="result-inner">
          <div className="historic-results-box">

            {/* 総合点 */}
            <div className="score__grid">
              <span className="item1">総合点</span>
              <span className="score"><b>{parseInt((unregisteredStroopTotalScore.current / 2) + (unregisteredNumberTotalScore.current * 7.15))}</b><small>/100</small></span><span className="item3">点</span>
            </div>
            {/* eslint-disable-next-line */}
            {/* SHOW DEVIATION */}
            {gender && age && <div className="deviation-container" style={{width:`${totalDeviation}`+'%'}}>
              {totalDeviation <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 {totalDeviation}</p>
                :
                <p className="deviation-text">偏差値 {totalDeviation}</p>
              }
            </div>}

            {/* 注意力 */}
            <div className="score__grid">
              <span className="item1">注意力テスト</span>  {/*Stroop test*/}
              <span className="score"><b>{parseInt(unregisteredStroopTotalScore.current / 2)}</b><small>/50</small></span>
              <span className="item3">点</span>
            </div>
            {/* eslint-disable-next-line */}
            {/* SHOW DEVIATION */}
            {gender && age && <div className="deviation-container" style={{width:`${judgementDeviation}`+'%'}}>
              {judgementDeviation <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 {judgementDeviation}</p>
                :
                <p className="deviation-text">偏差値 {judgementDeviation}</p>
              }
            </div>}

            {/* 処理機能 */}
            <div className="score__grid">
              <span className="item1">処理機能テスト</span>  {/*Number*/}
              <span className="score"><b>{parseInt(unregisteredNumberTotalScore.current * 7.15)}</b><small>/50</small></span>
              <span className="item3">点</span>
            </div>
            {/* eslint-disable-next-line */}
            {/* SHOW DEVIATION */}
            {gender && age && <div className="deviation-container" style={{width:`${taskDeviation}`+'%'}}>
              {taskDeviation <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 {taskDeviation}</p>
                :
                <p className="deviation-text">偏差値 {taskDeviation}</p>
              }
            </div>}

            {/* 記憶力 ONLY AVAILABLE IN FULL VERSION*/}

          </div>
          </div>
        </>
      }
      {!isBusy && testPlottingResultsArray.current[0] && showingDeviationBox &&
        <>
          <div className="result-inner">
            <div className="historic-results-box">
              <p className="points-text">30歳以上の方は年齢・性別を入力することで、同年代・性別と比較した偏差値を確認することができます</p>
              <br></br>
              <div className="continue-button" onClick={handleShowModal}><h2>偏差値を確認する</h2></div>
              {/* <Link to=""　className="continue-button" onClick={() => handleShowModal()}><h2>偏差値を確認する</h2></Link> */}
            </div>
          </div>
        </>
      }
      {!isBusy && testPlottingResultsArray.current[0] &&
        <>
          <div className="result-inner">
            <div className="historic-results-box">
            {currentHospital && <p className="points-text" dangerouslySetInnerHTML={{ __html: HospitalObject[currentHospital].customText }} />}
            {/* <p className="points-text">あなたの脳の状態はいかがでしたか？「BrainSuite」では、こういったテストでは測ることの出来ない実際の脳の状態を測定可能！人工知能（AI）を用いて海馬体積や微細な萎縮程度を精密に解析し、将来の認知機能低下の兆候を計測します。</p> */}
            <br></br>
            {/* <a href="https://www.brainsuite.jp" target="_blank" rel="noopener noreferrer"><button className="show-words-btn">詳細はこちら</button></a> */}
            {currentHospital && <a href={HospitalObject[currentHospital].customURL} target="_blank" rel="noopener noreferrer"><button className="show-words-btn">{HospitalObject[currentHospital].customButtonText}</button></a>}
            </div>

            <div>
              <p className="follow-us-text">FOLLOW US</p>
              <p className="horizontal-line">_________</p>
              <p className="line-text">
                LINE公式アカウントでは脳の健康に関する
                <br />
                お役立ち情報を配信中！
              </p>
              <div className="line-button-container">
                <a href="https://lin.ee/GGS5qhK"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="64" width="auto" border="0" /></a>
              </div>
            </div>

            <br></br>

            <div>
              <img src={logo} alt="logo-image" width="20%" height="20%"/>
            </div>

          </div>
        </>
      }

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
          <h3 className="login_color">性別・年齢を入力する</h3>

            <div className="modal-inputs">
              <div className="input-field">
                <label><b>性別:</b></label>
                <div>
                  <label>
                  <input type="radio" value="male" checked={tempGender === 'male'} onChange={(e) => setTempGender(e.target.value)} />
                    男性
                  </label>
                  <label>
                  <input type="radio" value="female" checked={tempGender === 'female'} onChange={(e) => setTempGender(e.target.value)} />
                    女性
                  </label>
                  <label>
                  <input type="radio" value="other" checked={tempGender === 'other'} onChange={(e) => setTempGender(e.target.value)} />
                    その他
                  </label>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="age"><b>年齢(半角):</b></label>
                <br></br>
                {isCustomAge ? (
                  <input
                    type="number"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    onBlur={() => {
                        if(!age) {
                            setIsCustomAge(false);  // If no custom age is set, revert back to dropdown
                        }
                    }}
                  />
                ) : (
                  <select className="modal-select-bar" id="age" name="age" value={age} onChange={handleAgeChange}>
                    {Array.from({ length: 41 }, (_, i) => 30 + i).map((ageValue) => (
                      <option key={ageValue} value={ageValue}>
                        {ageValue}
                      </option>
                    ))}
                    <option value="custom">カスタム年齢</option>
                  </select>
                )}
              </div>
            </div>
            <br></br>
            <button className="modal-confirm-btn" onClick={handleCancel}>戻る</button>
            <button className="modal-confirm-btn" onClick={handleConfirm} disabled={!tempGender}>偏差値を確認する</button>

          </div>
        </div>
      )}
    </>
  )
};
