//No visits => Display only Brain Icon and Start button
//1 Visit => Total Score out of 300!!
//Ages: 30-39
//      40-49
//      50-59
//      60-69
//SCORING:
//Word Memory Part 1  15 is 50   Each answer = 3.34points
//Word Memory Part 2  15 is 50   Each answer = 3.34points
//Stroop A  100 is 50            Each answer = 0.5point
//Stroop B  100 is 50            Each answer = 0.5point
//Number Memory  7 is 50         Each answer = 7.14points
//Number Mem Rev 7 is 50         Each answer = 7.14points

//業務処理機能
//記憶・処理機能
//判断・処理機能

//  この分類です。
// 【記憶・処理能力】　WordMemory Total
//  記憶力テスト 短期
//  記憶力テスト 長期
// 【業務処理能力】　　Number Memory Total
//  数唱テスト 順唱
//  数唱テスト 逆唱
// 【判断・処理能力】　　Stroop Total
//  ストループテスト1
//  ストループテスト2
//記憶・処理能力  WordMemory Total
//業務処理能力  Number Memory Total
//判断・処理能力  Stroop Total

import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { logout, useAuthDispatch, useAuthState } from "../../Context";
import { Link } from "react-router-dom";
import "./results-history.css";
import ExamIcon from "../../Images/ExamIcon.svg";
import ArrowUpBlue from "../../Images/arrow-blue-up.svg";
import ArrowUpGrey from "../../Images/arrow-grey-up.svg";
import ArrowDownGrey from "../../Images/arrow-grey-down.svg";
import ArrowDownRed from "../../Images/arrow-red-down.svg";
import { NewVisit } from '../../Pages/NewVisit';
import HeaderLogo from '../HeaderLogo/HeaderLogo'

export default function ResultsPage() {
  const [isBusy, setBusy] = useState(true);
  const dispatch = useAuthDispatch();
  let resultsRef = useRef();

  const userDetails = useAuthState();
  const user = userDetails.user;

  let totalScores = useRef([]);
  let deviationValues = useRef([]);
  let takenDates = useRef([]);
  //let testPlottingResultsArray = useRef([]);
  let deviationBarValues = useRef([])
  let resultTemplate = useRef([]);
  let testing_allowed = useRef(true);
  let textStyleThreshold = 20
  let nextAvailableTestDate = useRef()

  useEffect(() => {
    let csrftoken = Cookies.get("csrf_refresh_token");
    fetch("/api/results", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRFToken": csrftoken,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log("Results in React", response);
        updateResults(response);
        setBusy(false);
      })
      .catch((err) => {
        console.log("err", err);
        logout(dispatch);
      });
    // eslint-disable-next-line
  }, []);


  function formatDate(dateString) {
    const year = dateString.substring(2, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    return `${year}/${month}/${day}`;
  }

  const updateResults = (response) => {
    resultsRef.current = response;
    console.log("resultsRef in updateResults", resultsRef.current);
    parseVisits(resultsRef.current);
  };

  // const calculateDeviationBarValues = (deviationValues) => {
  //   function biggestNumberInArray(arr) {
  //     const max = Math.max(...arr, 80);
  //     return max;
  //   }
  //   //console.log("deviationValues", deviationValues)
  //   let maxDeviation = biggestNumberInArray(deviationValues)
  //   let deviationBarValues = []
  //   deviationValues.forEach(element => {
  //     let x  = element * 100 / maxDeviation
  //     deviationBarValues.push(x)
  //   });
  //   //console.log("maxDeviation",maxDeviation)
  //   //console.log("deviationBarValues",deviationBarValues)
  //   return deviationBarValues
  // }

  const calculateDeviationBarValues = (deviationValues) => {
    // This function converts the values of the total Deviation arrays into an equivalent percentage for its blue bar graph to properly show.
    // if greater than 80 then it'll use 100% of the width. If less than 20 then it'll use 5% (not 0% since values less than 5% is hard to see on screen and pure white doesn't look good)
    // if a deviation array comes as [12, 50, 90] it's equivalent percentage array will be [0, 50, 100]
    let deviationPercentagesArray = []
    deviationValues.forEach(element => {
      element <= 20 ? deviationPercentagesArray.push(5) : element >= 80 ? deviationPercentagesArray.push(100) : deviationPercentagesArray.push(Math.round(element))
    })

    //console.log("deviationPercentagesArray",deviationPercentagesArray)
    return deviationPercentagesArray
  }

  const parseVisits = (results) => {
    console.log("Results only", results.results);
    console.log("Latest result only", results.results[0]);

    testing_allowed.current = results.is_testing_allowed
    console.log("is testing allowed", testing_allowed.current)
    console.log("from_age", results.from_age)
    console.log("to_age", results.to_age)
    console.log("next_available_date", results.next_available_date)
    nextAvailableTestDate.current = results.next_available_date


    for (let i = 0; i < results.results.length; i++) {
      if (results.results[i]) {
        deviationValues.current = [...deviationValues.current, results.results[i]['total_deviation_value']];
      }
    }

    //deviationValues.current = [56, 19, 121, 43]  // Hack here deviation values
    deviationBarValues.current = calculateDeviationBarValues(deviationValues.current);
    //console.log("deviationBarValues.current", deviationBarValues.current)

    for (let i = 0; i < results.results.length; i++) {
      if (results.results[i]) {
        totalScores.current = [...totalScores.current, results.results[i]['total_score']];
        console.log("results.results[i]['taken_date']", results.results[i]['taken_date'])
        if (i === 0) {
          resultTemplate.current = [
            ...resultTemplate.current,
            <>
            <div className="score__grid">
            <div>
              {/* <span className="item1">第{i + 1}回</span> */}
              <span className="item1">{formatDate(results.results[i]['taken_date'])}</span>
            </div>
            <div>
              <span className="score">
                <b>{results.results[i]['total_score']}</b><small>/100</small>
              </span>
              <span className="item3">点</span>
            </div>
            </div>
            {/* eslint-disable-next-line */}
            <div className="deviation-container" style={{width:`${deviationBarValues.current[i]}`+'%', background:`${results.results[i]['total_score']}` > 0 ? '' : '#dadada'}}>
              {deviationBarValues.current[i] <= textStyleThreshold ?
                <p className="deviation-gray-text">偏差値 { deviationValues.current[i]}</p>
                :
                <p className="deviation-text">偏差値 {deviationValues.current[i]}</p>
              }
            </div>
            </>
          ]
        } else {
          resultTemplate.current = [
            ...resultTemplate.current,
            <>
              <div className="score__grid">
                <div>
                  {/* <span className="item1">第{i+1}回 {results.results[i]['taken_date']}</span> */}
                  <span className="item1">{formatDate(results.results[i]['taken_date'])}</span>
                </div>
                <span>
                <span className="score">
                  <b>{results.results[i]['total_score']}</b>
                  <small>/100</small>
                </span>
                <span className="item3">点</span>

                {results.results[i]['diff_total_score'] ?
                  (<span className="item4">
                    {
                      i === results.results.length - 1 ?
                      (results.results[i]['diff_total_score'] > 0) ? (
                          <ArrowUpBlueComp />
                        ) : (
                          <ArrowDownRedComp />
                        )
                      :
                      (results.results[i]['diff_total_score'] > 0) ? (
                        <ArrowUpGreyComp />
                      ) : (
                        <ArrowDownGreyComp />
                      )
                    }
                    </span>)
                  :
                  ""
                }
                <span className="item5">
                  {results.results[i]['diff_total_score'] && ((results.results[i]['diff_total_score'] <=0 ? "" : "+") + (results.results[i]['diff_total_score']))}
                </span>
                </span>
              </div>
              {/* eslint-disable-next-line */}
              <div className="deviation-container" style={{width:`${deviationBarValues.current[i]}`+'%', background:`${results.results[i]['total_score']}` > 0 ? '' : '#dadada'}}>

                {deviationBarValues.current[i] <= textStyleThreshold ?
                  <p className="deviation-gray-text">偏差値 {deviationValues.current[i]}</p>
                :
                  <p className="deviation-text">偏差値 {deviationValues.current[i]}</p>
                }

              </div>
            </>
          ]
        }
      //console.log(" totalScores.current", totalScores.current);
    }
    }
    //console.log("deviationValues.currentzzzzz", deviationValues.current)
    //console.log("deviationBarValues.currentzzzzzz",deviationBarValues.current)
    resultTemplate.current = resultTemplate.current.reverse();
  };

  const ArrowUpBlueComp = () => {
    return (
      <span>
        <img src={ArrowUpBlue} height="16" width="20" alt="青矢印上"/>
      </span>
    );
  };

  const ArrowDownRedComp = () => {
    return (
      <span>
        <img src={ArrowDownRed} height="16" width="20" alt="赤矢印下"/>
      </span>
    );
  };

  const ArrowUpGreyComp = () => {
    return (
      <span>
        <img src={ArrowUpGrey} height="16" width="20" alt="矢印上"/>
      </span>
    );
  };

  const ArrowDownGreyComp = () => {
    return (
      <span>
        <img src={ArrowDownGrey} height="16" width="20" alt="矢印下"/>
      </span>
    );
  };


  return (
    <>
      <HeaderLogo />
      {!isBusy && (
          <>
            <div className="head welcome-text-button-results-container">
              <div className="welcome-text-container">
                <h1>今の海馬の状態を<br />チェックしましょう</h1>
              </div>
            </div>
            {/* {!totalScores.current[0] && ( */}
              <>
                <div className="text-box">
                  <div className="flex">
                    <div className="exam-icon">
                      <img src={ExamIcon} alt="海馬の状態チェック"/>
                    </div>
                    <p className="instructions-text">
                    簡単なテストで今の海馬の状態をチェック！<br></br>
                    合計２問、所要時間は約5分です。
                    </p>
                  </div>
                </div>
              </>

            {(user.is_admin || testing_allowed ) &&
              <div className="new-visit-button-container"><NewVisit testing_allowed={testing_allowed.current} next_available_date ={ nextAvailableTestDate.current }/></div>
            }

            <>
            <Link to="/test/jankenpon" className="continue-button">
              <h2>JanKenPon</h2>
            </Link>
            {totalScores.current.length > 0 && (
              <>
                <div className="result-inner">
                <div className="historic-results-box">
                  <h2 className="historic-results-header-text">
                    過去のテスト結果
                  </h2>
                  {resultTemplate.current}
                </div>
                </div>
              </>
            )}
            </>
        </>
      )}
    </>
  )
};
